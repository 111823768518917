import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import { useSCTier, validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { assignSCTierToUser } from "../services/user";
import { useEffect, useState } from "react";
import SCForm from "../components/profile/SCForm";
import { useAuth0 } from "@auth0/auth0-react";
import VerifyEmail from "../components/VerifyEmail";
import { profile } from "console";
import {RegistrationFormType} from "../constants";

const RegistrationSC = () => {
    // SC for Standard Chartered
    const navigate = useHistory();
    const { t, i18n } = useTranslation();
    const { user } = useAuth0();
    const { getSCTier, clearSCTier, getSCToken, clearSCToken } = useSCTier();
    const [scTier, setScTier] = useState(null);
    const [scToken, setScToken] = useState(null);
    const [validations, setValidations] = useState<ValidationInterface>({
        salutations: [validateRequired],
        first_name: [validateRequired],
        last_name: [validateRequired],
        gender: [validateRequired],
        _custom: [
            formData => validateRequired('date_of_birth', formData.year, formData.month),
            formData => validateDob('date_of_birth', formData.year, formData.month),
        ]
    });

    const beforePatch = data => {
        let scData = { membership_tier: scTier };
        // console.log('new data', { ...data, ...scData })
        return { ...data, ...scData };
    };

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);
        const res = await assignSCTierToUser(scToken, user.email); // api
        if (res.success) {
            clearSCToken();
            clearSCTier();
            navigate.push(`/${i18n.language}/registration-success`, {
                state: {
                    tier: scTier,
                }
            });
            return resolve(null);
        } else {
            return resolve(t('error.profile_migrate_failure'));
        }
    });

    const beforeNext = (current, next) => {
        let rules = { ...validations };
        if (next === 1) {
            rules.email = [validateRequired, validateEmail];
            rules.mobile = [validateRequired, validateMobile];
            rules.house_or_building_estate = [validateRequired];
            rules.road_or_street = [validateRequired];
            rules.district = [validateRequired];
            rules.country = [validateRequired];
        }
        if (next === 2) {
            rules._custom.push(formData => !formData.consent ? t('error.missing_tnc') : null);
            rules._custom.push(formData => !formData.confirm_email ? t('error.confirmedEmailIsCorrect') : null);
        }
        setValidations(rules);
        return true;
    }

    const beforePrev = (current, prev) => {
        let rules = { ...validations };
        if (prev === 0) {
            delete rules.email;
            delete rules.mobile;
            delete rules.house_or_building_estate;
            delete rules.road_or_street;
            delete rules.district;
            delete rules.country;
        }
        if (prev === 1) {
            rules._custom.splice(2, 2);
        }
        setValidations(rules);
        return true;
    }

    useEffect(() => {
        if (!user) return;
        setScTier(getSCTier());
        setScToken(getSCToken());
    }, [user]);

    return (
        <>
            {scTier && scToken && (<>
                {/* temp hide verify email box  */}
                {/* {!user?.email_verified && (
                    <VerifyEmail email={user?.email} />
                )} */}

                {/* {user?.email_verified &&  */}
                    <RegistrationForm
                        validations={validations}
                        labelsWithStar={{'date_of_birth': true}}
                        beforePatch={beforePatch}
                        beforeNext={beforeNext}
                        beforePrev={beforePrev}
                        afterSubmit={afterSubmit}
                        formType={RegistrationFormType.EXISTING}
                    />
                 {/* } */}
            </>)}

            {(!scTier || !scToken )&& <SCForm />}
        </>
    );
};

export default RegistrationSC;
