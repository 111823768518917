import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./HamburgerMenu.css";
import i18n from "i18next";

const Navbar = (profile) => {
    const [isMobile, setIsMobile] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);


    useEffect(() => {
        if (!profile || Object.keys(profile).length === 0) return;
        else {
            setLoggedIn(true);
        }
    }, [profile]);

    return (
        <nav className="navbar">
            <ul
                className={isMobile ? "nav-links-mobile" : "nav-links"}
                onClick={() => setIsMobile(false)}
            >
                <Link to={`/${i18n.language}/whats-club-bravo`} className="club-bravo">What is Club Bravo</Link>
                <Link to={`/${i18n.language}/reward-list`} className="rewards">Rewards</Link>
                <Link to={`/${i18n.language}/scan-intro`} className="scan-ticket">Scan Ticket</Link>
                <Link to={`/${i18n.language}/how-to`} className="faq">FAQ</Link>
                {!loggedIn && <Link to={`/${i18n.language}/registration`} className="register">Register</Link>}
                <a href={`https://www.hkphil.org/${i18n.language}/form/contact/entry`} className="contact-us">Contact Us</a>
            </ul>
            <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                {isMobile ? (
                    <i className="fas fa-times"></i>)
                    : (
                        <i className="fas fa-bars"></i>
                    )}
            </button>
        </nav>
    );
}
const mapStateToProps = state => ({
    profile: state.shared.profile,
});

export default Navbar;
