import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import { useOldMember, validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { setProfileIsMigrated } from "../services/user";
import { useEffect, useState } from "react";
import MigrationForm from "../components/profile/MigrationForm";
import { useAuth0 } from "@auth0/auth0-react";
import VerifyEmail from "../components/VerifyEmail";
import {RegistrationFormType} from "../constants";

const RegistrationExisting = () => {
    const navigate = useHistory();
    const { t, i18n } = useTranslation();
    const { user } = useAuth0();
    const { getOldMember, clearOldMember } = useOldMember();
    const [oldMember, setOldMember] = useState(null);
    const [validations, setValidations] = useState<ValidationInterface>({
        salutations: [validateRequired],
        first_name: [validateRequired],
        last_name: [validateRequired],
        gender: [validateRequired],
        _custom: [
            formData => validateRequired('date_of_birth', formData.year, formData.month),
            formData => validateDob('date_of_birth', formData.year, formData.month),
        ]
    });

    const beforePatch = data => {
        let old = { ...oldMember };
        delete old.email;
        for(let i in old) {
            if (!old[i]) delete old[i];
        }
        return { ...data, ...old };
    };

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);
        const res = await setProfileIsMigrated(oldMember);
        if (res.success) {
            clearOldMember();
            navigate.push(`/${i18n.language}/registration-success`, {
                state: {
                    tier: oldMember.membership_tier,
                }
            });
            return resolve(null);
        } else {
            return resolve(t('error.profile_migrate_failure'));
        }
    });

    const beforeNext = (current, next) => {
        let rules = { ...validations };
        if (next === 1) {
            rules.email = [validateRequired, validateEmail];
            rules.mobile = [validateRequired, validateMobile];
            rules.house_or_building_estate = [validateRequired];
            rules.road_or_street = [validateRequired];
            rules.district = [validateRequired];
            rules.country = [validateRequired];
        }
        if (next === 2) {
            rules._custom.push(formData => !formData.consent ? t('error.missing_tnc') : null);
            rules._custom.push(formData => !formData.confirm_email ? t('error.confirmedEmailIsCorrect') : null);
        }
        setValidations(rules);
        return true;
    }

    const beforePrev = (current, prev) => {
        let rules = { ...validations };
        if (prev === 0) {
            delete rules.email;
            delete rules.mobile;
            delete rules.house_or_building_estate;
            delete rules.road_or_street;
            delete rules.district;
            delete rules.country;
        }
        if (prev === 1) {
            rules._custom.splice(2, 2);
        }
        setValidations(rules);
        return true;
    }

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.registration_existing')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.registration_existing')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(() => {
        if (!user) return;
        setOldMember(getOldMember());
    }, [user]);

    return (
        <>
            {oldMember && (<>
                {/* temp hide verify email box  */}
                {/* {!user?.email_verified && (
                    <VerifyEmail email={user?.email} />
                )} */}

                {/* {user?.email_verified &&  */}
                    <RegistrationForm
                        validations={validations}
                        labelsWithStar={{'date_of_birth': true}}
                        beforePatch={beforePatch}
                        beforeNext={beforeNext}
                        beforePrev={beforePrev}
                        afterSubmit={afterSubmit}
                        formType={RegistrationFormType.EXISTING}
                    />
                 {/* } */}
            </>)}

            {!oldMember && <MigrationForm />}
        </>
    );
};

export default RegistrationExisting;
