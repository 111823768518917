import { Route, Switch } from 'react-router-dom';
import AuthRoute from './authRoute';
import Index from "./pages/Index"
import NotFound from "./pages/NotFound"
import RegistrationFree from "./pages/RegistrationFree"
import RegistrationRed from "./pages/RegistrationRed"
import RegistrationExisting from "./pages/RegistrationExisting"
import RegistrationSC from "./pages/RegistrationSC"
import Scanner from './pages/Scanner';
import RewardDetail from './pages/RewardDetail';
import RewardList from './pages/RewardList';
import Asiamiles from './pages/Asiamiles';
import WhatsClubBravo from './pages/WhatsClubBravo';
import MemberDetail from './pages/MemberDetail';
import ContactUs from './pages/ContactUs';
import AsiaPayFail from './pages/AsiaPayFail';
import AsiaPayError from './pages/AsiaPayError';
import PointTransactionLog from './pages/PointTransactionLog';
import ScanPreview from './pages/ScanPreview';
import MemberDetailEdit from './pages/MemberDetailEdit';
import HowTo from './pages/HowTo';
import Registration from './pages/Registration';
import PersonalDetail from './pages/PersonalDetail';
import ScanSuccess from './pages/ScanSuccess';
import ScanFailed from './pages/ScanFailed';
import RedeemFail from './pages/RedeemFail';
import RedeemSuccess from './pages/RedeemSuccess';
import TermsAndConditions from './pages/TermsAndConditions';
import ScanIntro from './pages/ScanIntro';
import Login from './pages/Login';
import RegistrationSuccess from './pages/RegistrationSuccess';
import RegistrationYA from "./pages/RegistrationYA";
import { RewardType } from './constants';
import RegistrationEventResult from "./pages/RegistrationEventResult";
import StaticPage from "./pages/StaticPage";
import RegistrationFreeTierUpgrade from "./pages/RegistrationFreeTierUpgrade";
import JoinClubBravo from "./pages/JoinClubBravo";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/:lang/" component={Index} />
            <Route exact path="/:lang/asia-pay-error" component={AsiaPayError} />
            <Route exact path="/:lang/asia-pay-fail" component={AsiaPayFail} />
            <Route exact path="/:lang/contact-us" component={ContactUs} />
            <Route exact path="/:lang/how-to" component={HowTo} />
            <Route exact path="/:lang/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/:lang/registration" component={Registration} />
            <Route exact path="/:lang/registration-existing" component={RegistrationExisting} />
            <Route exact path="/:lang/registration-free" component={RegistrationFree} />
            <Route exact path="/:lang/registration-red" component={RegistrationRed} />
            <Route exact path="/:lang/registration-sc" component={RegistrationSC} />
            <Route exact path="/:lang/registration-free-tier-upgrade" component={RegistrationFreeTierUpgrade} />
            <Route exact path="/:lang/registration-ya" component={RegistrationYA} />
            <Route exact path="/:lang/registration-success" component={RegistrationSuccess} />
            <Route exact path="/:lang/reward-detail/:alias" render={(props) => <RewardDetail {...props} rewardType={RewardType.ITEM} />} />
            <Route exact path="/:lang/event/:alias" render={(props) => <RewardDetail {...props} rewardType={RewardType.EVENT} />} />
            <Route exact path="/:lang/reward-list" component={RewardList} />
            <Route exact path="/:lang/redeem-fail" component={RedeemFail} />
            <Route exact path="/:lang/redeem-success" component={RedeemSuccess} />
            <Route exact path="/:lang/scan-preview" component={ScanPreview} />
            <Route exact path="/:lang/scanner" component={Scanner} />
            <Route exact path="/:lang/scan-intro" component={ScanIntro} />
            <Route exact path="/:lang/scan-success" component={ScanSuccess} />
            <Route exact path="/:lang/scan-failed" component={ScanFailed} />
            <Route exact path="/:lang/whats-club-bravo" component={WhatsClubBravo} />
            <Route exact path="/:lang/registration-event-result" component={RegistrationEventResult} />
            <Route exact path="/:lang/campaign/:alias" render={(props) => <StaticPage alias={props.match.params.alias} />} />
            <Route exact path="/:lang/join-club-bravo" component={JoinClubBravo} />

            {/* protected */}
            <AuthRoute path="/:lang/member-detail" component={MemberDetail} />
            <AuthRoute path="/:lang/personal-detail" component={PersonalDetail} />
            <AuthRoute path="/:lang/member-detail-edit" component={MemberDetailEdit} />
            <AuthRoute path="/:lang/point-transaction-log" component={PointTransactionLog} />
            <AuthRoute path="/:lang/asiamiles" component={Asiamiles} />

            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
