import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./ImageSlider.css";

type ImageSliderProps = {
    staticContents: any[]
}

export function ImageSlider({ staticContents }: ImageSliderProps) {
    const [banners, setBanners] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { t, i18n } = useTranslation();
    const navigate = useHistory();

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
    });

    useEffect(() => {
        const updatedBanners = staticContents.map(staticContent => ({
            alias: staticContent.alias,
            externalAlias: staticContent.custom_specifications?.['external_alias'] ?? null,
            mobileImageUrl: (staticContent?.mobileIcon && staticContent?.mobileIcon[i18n.language]) ?
                staticContent?.mobileIcon[i18n.language] : null,
            imageUrl: (staticContent?.icon && staticContent?.icon[i18n.language]) ?
                staticContent?.icon[i18n.language] :
                (
                    staticContent?.image_url ?
                        staticContent?.image_url[i18n.language] :
                        staticContent?.image?.path
                )
        }));
        setBanners(updatedBanners);

    }, [windowWidth, staticContents, i18n.language]);

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex((index) => (index + 1) % banners.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, [banners.length]);

    function showNextImage() {
        setImageIndex((index) => (index + 1) % banners.length);
    }

    function showPrevImage() {
        setImageIndex((index) => (index - 1 + banners.length) % banners.length);
    }

    function handleImageClick() {
        if (banners[imageIndex].externalAlias) {
            window.open(`${banners[imageIndex].externalAlias}`, "_blank");
            return;
        }
        else {
            navigate.push(`/${i18n.language}/campaign/${banners[imageIndex].alias}`, {
                state: {
                    alias: banners[imageIndex].alias,
                    staticPage: staticContents
                }
            });
        }
    }

    return (
        <>
            {banners.length > 0 && (
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                    <div style={{width: "100%", height: "100%", display:"flex" }}>
                        {banners.map(banner => (
                            <img
                                key={banner.imageUrl}
                                src={windowWidth < 767 ? banner.mobileImageUrl : banner.imageUrl}
                                className={"img-slider-image"}
                                style={{
                                    translate: `${-100 * imageIndex}%`,
                                }}
                                onClick={handleImageClick}
                            />
                        ))
                        }
                    </div>


                    <button onClick={showPrevImage} className={"img-slider-left-btn"}>
                        <span style={{ color: "white", fontSize: "2rem" }}>{"<"}</span>
                    </button>


                    <button onClick={showNextImage} className={"img-slider-right-btn"}>
                        <span style={{ color: "white", fontSize: "2rem" }}>{">"}</span>
                    </button>


                    <div
                        style={{
                            position: "absolute",
                            bottom: ".5rem",
                            left: "50%",
                            translate: "-50%",
                            display: "flex",
                            gap: ".25rem",
                        }}>
                        {banners.map((_, index) => (
                            <button
                                className={`img-slider-dot-btn ${index === imageIndex ? 'active' : ''}`}
                                key={index}
                                onClick={() => setImageIndex(index)}
                            >
                                {index === imageIndex ? "●" : "○"}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
export default ImageSlider;

